import * as React from "react"
import { Link } from "gatsby"
import Layout from "../layouts/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="overflow-hidden bg-white mx-auto max-w-xl lg:max-w-7xl pb-16 pt-32 px-4 sm:px-6 lg:px-8 md:pt-40 lg:pt-48 lg:pb-24">
        <div className="relative z-10 mb-8 md:mb-2">
          <div className="max-w-prose text-base lg:max-w-none">
            <p className="font-semibold leading-6 text-sky-700">DEC Housing</p>
            <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-sky-900 sm:text-4xl">
              404 Page not found
            </h2>
          </div>
        </div>
        <div className="relative">
          <div className="relative md:py-6">
            <div className="lg:grid lg:grid-cols-2 lg:gap-16">
              <div className="prose prose-lg prose-indigo text-gray-500 lg:max-w-none">
                  Sorry, we couldn’t find what you were looking for.<br /><br />

                   <Link to="/">Go to homepage</Link>.
              </div>
              <div className="prose prose-lg prose-indigo mt-6 text-gray-500 lg:mt-0">
                   
            </div>
          </div>
        </div>
      </div>
      </div>
     </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
